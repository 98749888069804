import Box from "@mui/material/Box";
import Blockquote from "components/Blockquote";
import DonateButton from "components/DonateButton";
import { Markdown } from "components/Markdown";

import * as React from "react";
import BlogLayout from "src/components/BlogLayout";
import Paragraph from "src/components/Paragraph";
import img1 from "src/images/community/brighter-future.png";

export default function Page() {
  return (
    <BlogLayout slug="brighter-future">
      <Box component="img" src={img1} display="block" sx={{ width: "100%" }} />
      <Blockquote mt={0}>
        "EOCP has given me resources and support to get into my own place. That
        has given me confidence to live a fulfilling life. They have also set up
        my son for a bright future. He can see a happy mom who can be fully
        present and not stressed about where to call home. Housing is so
        difficult in the Bay Area and EOCP has given me the biggest sense of
        relief. I don't think my situation would have turned out the way it did
        if it wasn't for their consistent support." V. Reddy
      </Blockquote>

      <Paragraph component="div">
        <Markdown>
          {`
At EOCP we honor our clients' personal stories and we believe that effective, **dignified care begins with genuinely understanding a person as who they are**, not just what happened to them.  V. Reddy's personal story below is no exception. 

V. Reddy was connected to EOCP via Alameda County's Probation Department.  She was in a tight spot as she lacked the income to secure housing and fully support her five year old son.  With only $700 a month in child support, V. Reddy could not afford market rate housing in the county.  Securing a job was also out of the question due to her disability.

V. Reddy was contemplating moving to another region and had obtained a Section 8 voucher in Solano County.  She was reluctant to make the move because her friends and family, who could support her and her son if needed, live in Alameda County.  It was clear to her and to her EOCP Case Manager that they needed to request a transfer of the housing voucher to Alameda County, which entails a very lengthy process.  

After waiting for **eight** long months **V. Reddy's dream of having her own home became reality**! The team's ongoing advocacy and persistence paid off last August when V. Reddy and her son moved into a two-bedroom unit in Union City where she pays $111/month. She is currently working with another partner agency, Bay Area Legal Aid, in appealing her disability claim and, if approved, this amount will substantially increase her income on a monthly basis.

          `}
        </Markdown>
      </Paragraph>
      <Blockquote component="div">
        Snapshot of Clients referred to EOCP by the Alameda County Probation
        Department
        <Paragraph component="div">
          <Markdown>{`
| | |
|---|---|
| **Total number of people served** | 106 |
| **Number of Dependent Children (under the age of 18)** | 16 |
| **Number of clients with a disabling condition** | 52 |
| **Average age of Clients** | 25-44 |
| **Race of Clients Served** | African American (81.4%), White (9.3%), American Indian (4.7%), multiple races (3.5%) |
| **Percentage of clients in permanent housing** | 61% |

`}</Markdown>
        </Paragraph>
      </Blockquote>
      <DonateButton />
    </BlogLayout>
  );
}
